import { Spin, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const Confirm = () => {
  const orders = useLocation().state;
  const { user } = useAppSelector((state) => state.app);
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  const addressDefault =
    JSON.parse(localStorage.getItem("addressDefault")) || user?.address?.[0];
  const navigate = useNavigate();
  const totaklValue = () => {
    let total = 0;
    if (orders && orders.length > 0)
      orders.map((p) => {
        total += p?.quantity * p?.product?.price_product;
      });
    return total;
  };

  const createdOrder = async () => {
    try {
      if (!user?.address?.length)
        return message.error("Bạn chưa thêm địa chỉ nhận hàng");
      onLoading({
        type: "LOGIN",
        value: true,
      });
      const res = await requestService.post("/profile/createdOrder", {
        data: {
          cart: orders,
          totalMoney: totaklValue(),
        },
      });
      if (res && res.data) {
        navigate("/paymentOrder", {
          state: res.data.data,
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
    onLoading({
      type: "LOGIN",
      value: false,
    });
  };
  return (
    <>
      <div className="bg_header ">
        <Link to="/" classNameName="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            classNameName="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 className="bg_header_name ">Xác nhận đơn hàng</h1>
        <div className="header_R"></div>
        <div>
          <span id="signout" className="btn_inforOut"></span>
        </div>
      </div>
      <div className="cart-page product-pay-page">
        <div className="address-select">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="location-dot"
            className="svg-inline--fa fa-location-dot icon-location"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              fill="currentColor"
              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"
            ></path>
          </svg>
          <div className="content">
            <p>Địa chỉ nhận hàng</p>
            <div className="card-address">
              <div className="card-body">
                {JSON.parse(localStorage.getItem("addressDefault")) && (
                  <div className="card-footer">
                    <button
                      type="button"
                      className="btn btn-add-address"
                      onClick={() => {
                        navigate("/address");
                      }}
                    >
                      <svg
                        style={{ width: "12px" }}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="circle-plus"
                        className="svg-inline--fa fa-circle-plus "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                        ></path>
                      </svg>
                      <span>Thêm địa chỉ mới</span>
                    </button>
                  </div>
                )}
                <div className="content">
                  <div className="card-address">
                    <div className="card-body">
                      <div className="item">
                        <h5>
                          {addressDefault?.nameReceive} |{" "}
                          <span> {addressDefault?.phone} </span>
                        </h5>
                        <p>{addressDefault?.address} </p>
                        <div
                          className="action"
                          onClick={() => navigate("/address")}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="angle-right"
                            className="svg-inline--fa fa-angle-right "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-prod mt-12">
          {orders &&
            orders?.map((order) => (
              <div className="item">
                <div className="card-img">
                  <img src={order?.product?.img_product} alt="" />
                </div>
                <div className="little-right">
                  <div className="tip">
                    <h5 className="name">{order?.product?.name_product}</h5>
                    <h5 className="total">
                      {order?.quantity} x{" "}
                      {order?.product?.price_product?.toLocaleString()}đ
                    </h5>
                  </div>
                  <div className="tip">
                    <h5 className="text-orange">
                      {(
                        order?.product?.price_product * order?.quantity
                      ).toLocaleString()}
                      đ
                    </h5>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="cell-group mt-12">
          <div className="cell-item right-img">
            <div className="cell-item-hd">
              <div className="cell-hd-title">Tổng số tiền</div>
            </div>
            <div className="cell-item-ft">
              <span className="text-orange">
                {totaklValue().toLocaleString()}đ
              </span>
            </div>
          </div>
        </div>
        <div className="cart-footer">
          <div className="left">
            <span>Cộng 1 sản phẩm</span>
          </div>
          <div className="right">
            <span>
              <p>Tổng cộng</p>
              <p className="total">{totaklValue().toLocaleString()}đ</p>
            </span>
            <span className="btn-pay btn-confirm" onClick={createdOrder}>
              Thanh toán
            </span>
          </div>
        </div>
      </div>
      <div className="notification-container notification-container-empty">
        <div></div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
    </>
  );
};

export default Confirm;
