import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Cart = () => {
  const [products, setProducts] = useState<any>([]);
  const carts = JSON.parse(localStorage.getItem("cart") as any);
  const [productConfirm, setProductConfirm] = useState<any>([]);
  const navigate = useNavigate();
  useEffect(() => {
    setProducts(carts);
  }, []);

  const totaklValue = () => {
    let total = 0;
    if (products && products.length > 0)
      products.map((p: any) => {
        total += p?.quantity * p?.product?.price_product;
      });
    return total;
  };
  return (
    <>
      <div className="bg_header ">
        <Link to="/" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 className="bg_header_name ">Giỏ hàng</h1>
        <div className="header_R"></div>
        <div>
          <span id="signout" className="btn_inforOut"></span>
        </div>
      </div>

      <div className="cart-page">
        <div className="cell-group">
          <div className="cell-item right-img">
            <div className="cell-item-hd">
              <div className="cell-hd-icon">
                <img src="/assets/images/store.png" alt="" />
              </div>
              <div className="cell-hd-title">Shopee VN</div>
            </div>
            <div className="cell-item-ft">
              <span>Chỉnh sửa</span>
            </div>
          </div>
        </div>
        <div className="list-prod">
          {products &&
            products?.map((c: any) => (
              <div className="item">
                <div className="cart-checkbox">
                  <input
                    type="checkbox"
                    name="check"
                    value="139"
                    onChange={(e) => {
                      if (e.target?.checked)
                        setProductConfirm([...productConfirm, c]);
                      else {
                        const newPro = productConfirm.filter(
                          (f: any) => f.product._id !== c?.product?._id
                        );
                        setProductConfirm(newPro);
                      }
                    }}
                  />
                </div>
                <div className="card-img">
                  <img src={c?.product?.img_product} alt="" />
                </div>
                <div className="little-right">
                  <div className="tip">
                    <h5 className="name">{c?.product?.name_product}</h5>
                    <h5 className="price">
                      {c?.product?.price_product?.toLocaleString()}đ
                    </h5>
                  </div>
                  <div className="quantity">
                    <span className="ant-input-group">
                      <button
                        type="button"
                        className="ant-btn ant-btn-default"
                        onClick={() => {
                          let newQuantity = c?.quantity;
                          if (newQuantity <= 1) return (newQuantity = 1);
                          else newQuantity = newQuantity - 1;
                          const newC = products.map((c1: any) => {
                            if (c1?.product._id === c?.product?._id)
                              return {
                                ...c1,
                                quantity: newQuantity,
                              };
                            return c1;
                          });
                          setProducts(newC);
                        }}
                      >
                        <span>-</span>
                      </button>
                      <div className="ant-input-number">
                        <div className="ant-input-number-input-wrap">
                          <input
                            autoComplete="off"
                            className="ant-input-number-input"
                            value={c?.quantity}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ant-btn ant-btn-default"
                        onClick={() => {
                          let newQuantity = c?.quantity;
                          newQuantity += 1;
                          const newC = products.map((c1: any) => {
                            if (c1?.product._id === c?.product?._id)
                              return {
                                ...c1,
                                quantity: newQuantity,
                              };
                            return c1;
                          });
                          setProducts(newC);
                        }}
                      >
                        <span>+</span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="cart-footer">
          <div className="left">
            <label htmlFor="all">
              <input
                type="checkbox"
                id="all"
                name="check"
                onChange={(e) => {
                  if (e.target?.checked) setProductConfirm(products);
                  else setProductConfirm([]);
                }}
              />
              Chọn Tất cả
            </label>
          </div>
          <div className="right">
            <span>Tổng số tiền</span>
            <span className="total">{totaklValue().toLocaleString()}đ</span>
            <span
              className="btn-pay"
              onClick={() => {
                if (!productConfirm?.length) {
                  message.error("Chưa chọn sản phẩm");
                } else {
                  const newP = products.filter((p: any) => {
                    return !productConfirm.some((pc: any) => p._id === pc._id);
                  });

                  setProducts(newP);
                  localStorage.setItem("cart", JSON.stringify(newP));
                  navigate("/confirm", {
                    state: productConfirm,
                  });
                }
              }}
            >
              Thanh toán
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
