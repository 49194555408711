import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
const Me = () => {
  const [chat, setChat] = useState();
  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }
  return (
    <div className="container_main">
      <LiveChatWidget
        license="17041149"
        visibility={chat?.visibility}
        onNewEvent={handleNewEvent}
        onVisibilityChanged={(data) => {
          setChat({
            availability: "online",
            visibility: data?.visibility,
          });
        }}
      />
      <div className="member-top">
        <Swiper
          className="mySwiper"
          loop
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide style={{ height: "250px" }}>
            <img
              src="https://down-vn.img.susercontent.com/file/vn-50009109-96f1144d954532e2562ec7fc21a0fa61"
              alt=""
              style={{ height: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide style={{ height: "250px" }}>
            <img
              src="https://bizweb.dktcdn.net/100/229/172/articles/shopee-3-3-ngang.jpg?v=1646216351303"
              alt=""
              style={{ height: "100%" }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="cell-group">
        <div className="cell-item right-img">
          <div className="cell-item-hd">
            <div className="cell-hd-icon">
              <img src="/assets/images/order.png" alt="" />
            </div>
            <div className="cell-hd-title">Đơn hàng của tôi</div>
          </div>
          <div className="cell-item-ft">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div className="member-grid">
        <Link className="member-item" to="/history">
          <img src="/assets/images/wallet.png" alt="" />
          <div className="member-item-text">Chờ xác nhận</div>
        </Link>
        <Link className="member-item" to="/history">
          <img src="/assets/images/help.png" alt="" />
          <div className="member-item-text">Chờ lấy hàng</div>
        </Link>
        <Link className="member-item" to="/history">
          <img src="/assets/images/shipping.png" alt="" />
          <div className="member-item-text">Đang giao</div>
        </Link>
        <Link className="member-item" to="/history">
          <img src="/assets/images/evaluate.png" alt="" />
          <div className="member-item-text">Đánh giá</div>
        </Link>
      </div>
      <div className="cell-group mt-12">
        <div className="cell-item right-img">
          <div className="cell-item-hd">
            <div className="cell-hd-icon">
              <img src="/assets/images/service.png" alt="" />
            </div>
            <div className="cell-hd-title">Dịch vụ của tôi</div>
          </div>
          <div className="cell-item-ft">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div className="member-grid">
        <Link className="member-item" to="/address">
          <img src="/assets/images/map.png" alt="" />
          <div className="member-item-text">Địa chỉ</div>
        </Link>
        <Link className="member-item" to="/list">
          <img src="/assets/images/collect.png" alt="" />
          <div className="member-item-text">Bộ sưu tập</div>
        </Link>
        <Link className="member-item" to="/history">
          <img src="/assets/images/track.png" alt="" />
          <div className="member-item-text">Lịch sử</div>
        </Link>
      </div>
      <div className="cell-group mt-12">
        <div className="cell-item right-img">
          <div className="cell-item-hd">
            <div className="cell-hd-icon">
              <img src="/assets/images/other.png" alt="" />
            </div>
            <div className="cell-hd-title">Khác</div>
          </div>
          <div className="cell-item-ft">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div className="member-grid">
        <Link className="member-item" to="/setting">
          <img src="/assets/images/setting.png" alt="" />
          <div className="member-item-text">Cài đặt hệ thống</div>
        </Link>
        <div
          style={{ cursor: "pointer" }}
          className="member-item"
          id="myButton-cskh"
          onClick={() =>
            setChat({
              availability: "online",
              visibility: "maximized",
            })
          }
          state={true}
        >
          <img src="/assets/images/phone.png" alt="" />
          <div className="member-item-text">Liên hệ CSKH</div>
        </div>
      </div>
      <div className="quick-action">
        <ul className="memberMenu">
          <li>
            <Link to="/deposit">
              <div className="cell-item-hd">
                <img src="/assets/images/deposit.png" alt="" />
                <span>Nạp tiền</span>
              </div>
              <div className="cell-item-ft">
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/withdraw">
              <div className="cell-item-hd">
                <img src="/assets/images/withdraw.png" alt="" />
                <span>Rút tiền</span>
              </div>
              <div className="cell-item-ft">
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
          </li>
          <li>
            {/* <Link to="/history-balance">
              <div className="cell-item-hd">
                <img src="/assets/images/history.png" alt="" />
                <span>Số tiền</span>
              </div>
              <div className="cell-item-ft">
               <i class="fas fa-chevron-right"></i>
              </div>
            </Link> */}
          </li>
          <li>
            <Link to="/history-balance">
              <div className="cell-item-hd">
                <img src="/assets/images/dep.png" alt="" />
                <span>Thống kê số tiền</span>
              </div>
              <div className="cell-item-ft">
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/bank">
              <div className="cell-item-hd">
                <img src="/assets/images/card.png" alt="" />
                <span>Tài khoản ngân hàng</span>
              </div>
              <div className="cell-item-ft">
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Me;
