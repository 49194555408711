import { message } from "antd";
import requestService from "api/request";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const Bank = () => {
  const { user } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  return (
    <>
      <div class="bg_header ">
        <span class="btn_back">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            class="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </span>
        <h1 class="bg_header_name ">Thẻ ngân hàng của tôi</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main">
        <div class="bank_container">
          <div class="card-bank">
            <div class="card-title">
              <h5>Tài khoản ngân hàng ({user?.bankList?.length})</h5>
            </div>
            {user?.bankList?.map((item) => (
              <div class="card-body">
                <div
                  class="item"
                  onClick={() =>
                    navigate("/add-bank", {
                      state: {
                        bank: item,
                      },
                    })
                  }
                  // async () => {
                  //   try {
                  //     await requestService.post("/profile/select-bank", {
                  //       data: {
                  //         bankInfo: item,
                  //       },
                  //     });
                  //     window.location.reload();
                  //   } catch (error) {
                  //     message("Thử lại");
                  //   }
                  // }
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="building-columns"
                    class="svg-inline--fa fa-building-columns bank-icon"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8.1-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zm128-96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
                    ></path>
                  </svg>
                  <div class="info">
                    <h5>{item?.authorName}</h5>
                    <h5>{item?.nameBank}</h5>
                    {user?.bankInfo?.numberBank === item?.numberBank &&
                      user?.bankInfo?.nameBank === item?.nameBank &&
                      user?.bankInfo?.authorName === item?.authorName && (
                        <p>
                          <span class="text-orange">[MẶC ĐỊNH]</span>
                        </p>
                      )}
                  </div>
                  <div class="right">
                    <span>{item?.numberBank}</span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="angle-right"
                      class="svg-inline--fa fa-angle-right "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-add-address"
              fdprocessedid="4z6l98"
              style={{ width: "100%", padding: "10px" }}
              onClick={() =>
                navigate("/add-bank", {
                  state: {
                    add: true,
                  },
                })
              }
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="circle-plus"
                class="svg-inline--fa fa-circle-plus "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{ width: "12px" }}
              >
                <path
                  fill="currentColor"
                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                ></path>
              </svg>
              <span>Thêm ngân hàng</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bank;
