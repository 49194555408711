import { Spin, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Payment = () => {
  const { state } = useLocation();
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  const submit = async () => {
    onLoading({
      type: "LOGIN",
      value: true,
    });
    try {
      const res = await requestService.post("/profile/payment-order", {
        data: {
          id: state?._id,
        },
      });
      if (res && res.data) {
        message.success(res.data.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Có lỗi xảy ra");
    }
    onLoading({
      type: "LOGIN",
      value: false,
    });
  };
  return (
    <>
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <span class="btn_back">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            class="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </span>
        <h1 class="bg_header_name ">Thanh toán</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="cart-page product-pay-page">
        <ul class="list-info-table card-white mt-12">
          <li>
            <span>Loại hàng hoá</span>
            <span>Đơn đặt hàng</span>
          </li>
          <li>
            <span>Mã đơn hàng</span>
            <span>{state?._id}</span>
          </li>
          <li>
            <span>Số tiền thanh toán</span>
            <span class="text-orange">
              {state?.totalMoney?.toLocaleString()}đ
            </span>
          </li>
        </ul>
        <div class="cell-group mt-12">
          <div class="cell-item right-img" onClick={submit}>
            <div class="cell-item-hd">
              <div class="cell-hd-icon">
                <img
                  src="/assets/images/balancepay.png"
                  alt=""
                  style={{ width: "42px", height: "auto" }}
                />
              </div>
              <div class="cell-hd-title">
                <h5>Xác nhận thanh toán</h5>
                <p>Thanh toán số dư tài khoản</p>
              </div>
            </div>
            <div class="cell-item-ft">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                class="svg-inline--fa fa-angle-right "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
    </>
  );
};

export default Payment;
