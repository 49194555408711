import { Modal, message } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const Product = () => {
  const location = useLocation();
  const product = location?.state;
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { user } = useAppSelector((state) => state.app);
  const [isBuyNow, setIsBuyNow] = useState(false);

  return (
    <div className="product-detail-page">
      <Modal
        centered
        footer={null}
        title={product?.name_product}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        width={500}
      >
        <div className="modal modal-buy-product">
          <div className="modal-root">
            <div className="pop-t">
              <div className="prod-img">
                <img src={product?.img_product} alt="" />
              </div>
              <div className="prod-info">
                <p className="name">{product?.name_product}</p>
                <h5 className="price">
                  {product?.price_product?.toLocaleString()}đ
                </h5>
              </div>
            </div>
            <div className="prod-number">
              <p>Số lượng</p>
              <div className="quantity">
                <span className="ant-input-group">
                  <button
                    type="button"
                    className="ant-btn ant-btn-default"
                    ant-click-animating-without-extra-node="true"
                    onClick={() => {
                      if (quantity <= 1) return setQuantity(1);
                      setQuantity(quantity - 1);
                    }}
                  >
                    <span>-</span>
                  </button>
                  <div className="ant-input-number">
                    <div className="ant-input-number-input-wrap">
                      <input
                        autoComplete="off"
                        className="ant-input-number-input"
                        value={quantity}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ant-btn ant-btn-default"
                    ant-click-animating-without-extra-node="true"
                    onClick={() => {
                      if (quantity > product?.count)
                        return setQuantity(product?.count);
                      setQuantity(quantity + 1);
                    }}
                  >
                    <span>+</span>
                  </button>
                </span>
              </div>
            </div>
            <div className="balance">
              <p>
                Thành tiền:{" "}
                <span className="price">
                  {(product?.price_product * quantity).toLocaleString()}đ
                </span>
              </p>
            </div>
            <div className="balance">
              <p>
                Số dư của tôi:{" "}
                <span className="price">{user?.money?.toLocaleString()}đ</span>
              </p>
            </div>
            <div className="modal-footer">
              {isBuyNow ? (
                <Link
                  to="/confirm"
                  state={[
                    {
                      product,
                      quantity,
                    },
                  ]}
                >
                  <button className="btn btn-confirm">Mua ngay</button>
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-confirm"
                  onClick={() => {
                    const productsCarrt = JSON.parse(
                      localStorage.getItem("cart")
                    );
                    if (productsCarrt) {
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([
                          {
                            product,
                            quantity,
                            time: new Date().getTime(),
                          },
                          ...productsCarrt,
                        ])
                      );
                    } else {
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([
                          {
                            product,
                            quantity,
                            time: new Date().getTime(),
                          },
                        ])
                      );
                    }
                    message.success("Đã thêm vào giỏ");
                    setIsOpen(false);
                  }}
                >
                  Thêm vào giỏ hàng
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Link
        to="/bank"
        className="btn-back"
        style={{
          color: "unset",
          background: "unset",
        }}
      >
        <i class="fas fa-chevron-left"></i>
      </Link>
      <div className="slide-product">
        <Swiper
          className="mySwiper"
          loop
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
        >
          <SwiperSlide>
            <img alt="" src={product?.img_product} />
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" src={product?.img_product} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="info">
        <div className="price">{product?.price_product?.toLocaleString()}đ</div>
        <h5 className="name">{product?.name_product}</h5>
        <div className="rate">
          <div className="left">
            <ul className="ant-rate" role="radiogroup">
              <li className="ant-rate-star ant-rate-star-full">
                <div>
                  <div className="ant-rate-star-first">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ant-rate-star-second">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li className="ant-rate-star ant-rate-star-full">
                <div>
                  <div className="ant-rate-star-first">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ant-rate-star-second">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li className="ant-rate-star ant-rate-star-full">
                <div>
                  <div className="ant-rate-star-first">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ant-rate-star-second">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li className="ant-rate-star ant-rate-star-half ant-rate-star-active">
                <div>
                  <div className="ant-rate-star-first">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ant-rate-star-second">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li className="ant-rate-star ant-rate-star-zero">
                <div>
                  <div className="ant-rate-star-first">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ant-rate-star-second">
                    <span
                      role="img"
                      aria-label="star"
                      className="anticon anticon-star"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="star"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <span className="sold">{product?.count} đã bán</span>
          </div>
          <span className="heart">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="heart"
              className="svg-inline--fa fa-heart "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
      <div className="info">
        <div className="title">Mô tả sản phẩm</div>
        <p className="des">{product?.description}</p>
      </div>
      <div className="info">
        <div className="item">
          <img src="/assets/images/shipping.png" alt="" />
          <span>Miễn phí vận chuyển</span>
        </div>
      </div>
      <div className="product-footer">
        <div className="left">
          <div className="item">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="message"
              className="svg-inline--fa fa-message "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z"
              ></path>
            </svg>
            <p>Chat ngay</p>
          </div>
          <div
            className="item"
            onClick={() => {
              setIsBuyNow(false);
              setIsOpen(true);
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="cart-plus"
              className="svg-inline--fa fa-cart-plus "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z"
              ></path>
            </svg>
            <p>Thêm vào giỏ hàng</p>
          </div>
        </div>
        <button
          type="button"
          className="btn-buy"
          onClick={() => {
            setIsBuyNow(true);
            setIsOpen(true);
          }}
        >
          Mua ngay
        </button>
      </div>
    </div>
  );
};

export default Product;
