import React from "react";
import { Link } from "react-router-dom";

const UpdateUser = () => {
  return (
    <>
      <div class="bg_header ">
        <Link to="/setting" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Cập nhật thông tin</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>

      <div class="container_main bg-white">
        <div class="form-page  ">
          <form>
            <div class="form_vertical marginTop">
              <ul>
                <li class="marginBottom">
                  <div for="full_name" class="form_T">
                    Tên thật <span class="red">yêu cầu</span>
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="full_name"
                      type="text"
                      placeholder="Vui lòng nhập tên thật"
                      name="full_name"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
                <li class="marginBottom">
                  <div for="nick_name" class="form_T">
                    Biệt danh của người dùng
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="nick_name"
                      type="text"
                      placeholder="Vui lòng nhập biệt danh của người dùng"
                      name="nick_name"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
                <li class="marginBottom">
                  <div for="phone" class="form_T">
                    Số điên thoại
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="phone"
                      type="text"
                      placeholder="Vui lòng nhập số điện thoại"
                      name="phone"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
              </ul>
              <div class="form_button">
                <input type="submit" class="w100" value="Gửi đi" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateUser;
