import React from "react";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  return (
    <>
      <div class="bg_header ">
        <Link to="/setting" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Thay đổi mật khẩu</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main bg-white">
        <div class="form-page  ">
          <form class="form_vertical">
            <div>
              <ul>
                <li class="marginBottom">
                  <div for="password" class="form_T">
                    Mật khẩu đăng nhập cũ
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="password"
                      type="text"
                      placeholder="Vui lòng nhập mật khẩu đăng nhập cũ"
                      name="password"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
                <li class="marginBottom">
                  <div for="new_password" class="form_T">
                    Xác nhận mật khẩu đăng nhập tài khoản
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="new_password"
                      type="text"
                      placeholder="Vui lòng nhập mật khẩu đăng nhập mới"
                      name="new_password"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
                <li class="marginBottom">
                  <div for="confirm_password" class="form_T">
                    Mật khẩu đăng nhập đăng nhập mới
                  </div>
                  <div class="form_In">
                    <input
                      class="w100"
                      id="confirm_password"
                      type="text"
                      placeholder="Vui lòng xác nhận mật khẩu đăng nhập"
                      name="confirm_password"
                    />
                    <span class="error_t"></span>
                  </div>
                </li>
              </ul>
              <p class="help-text marginBottom">
                *Quên mật khẩu, hãy liên hệ với dịch vụ khách hàng để sửa đổi
              </p>
              <div class="form_button">
                <input type="submit" class="w100" value="Xác nhận" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
