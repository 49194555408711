import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const List = () => {
  const [init, setinit] = useState(null);
  const loading = useLoading("INIT");
  const [products, setProducts] = useState([]);
  const [index, setIndex] = useState(0);
  const { onLoading } = useFnLoading();
  const getInits = async () => {
    onLoading({
      type: "INIT",
      value: true,
    });
    try {
      const res = await requestService.get("/profile/list");
      if (res && res.data) {
        setinit(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    onLoading({
      type: "INIT",
      value: false,
    });
  };
  useEffect(() => {
    getInits();
  }, []);
  useEffect(() => {
    if (init && init.length >= 0) setProducts(init[index]?.products);
  }, [index, init]);
  return (
    <>
      <div className="bg_header ">
        <h1 className="bg_header_name ">Phân loại</h1>
        <div className="header_R"></div>
        <div>
          <span id="signout" className="btn_inforOut"></span>
        </div>
      </div>
      <div className="category-page">
        <div className="goods-list">
          {init &&
            init?.map((i, idex) => (
              <div
                className={"item " + (index === idex && "active")}
                onClick={() => setIndex(idex)}
              >
                <img
                  src={i?.categoryId?.img}
                  alt=""
                  style={{
                    width: "30px",
                  }}
                />
                <p>{i?.categoryId?.name}</p>
              </div>
            ))}
        </div>
        <div className="goods-content">
          <div className="product-container">
            <div className="list-product">
              {products?.map((p) => (
                <Link className="item" to="/product" state={p}>
                  <div className="img-prod">
                    <img src={p?.img_product} alt="" />
                  </div>
                  <div className="info">
                    <h2>{p?.name_product}</h2>
                    <p className="text-orange">
                      {p?.price_product?.toLocaleString()} đ
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="notification-container notification-container-empty">
        <div></div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
    </>
  );
};

export default List;
