import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HistoryBalance = () => {
  const [history, setHistory] = useState([]);
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  useEffect(() => {
    const fetch = async () => {
      onLoading({
        type: "LOGIN",
        value: true,
      });
      try {
        const res = await requestService.get("/profile/history-withdraws");
        if (res && res.data) {
          setHistory(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
      onLoading({
        type: "LOGIN",
        value: false,
      });
    };
    fetch();
  }, []);
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Chi tiết tài chính</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main">
        <div class="page-tab">
          <div class="item active">Tất cả</div>
          {/* <div class="item">Lịch sử rút tiền</div>
          <div class="item">Lịch sử nạp</div> */}
        </div>
        <div class="balance-histories">
          {history?.map((item) => (
            <div class="card-item">
              <div class="card-header">
                {item?.isResolve ? " Người dùng rút tiền" : item?.type}

                {item?.isResolve === "PENDING" && (
                  <span
                    class="status"
                    style={{ backgroundColor: "rgb(224, 105, 13)" }}
                  >
                    Đang chờ
                  </span>
                )}
                {item?.isResolve === "RESOLVE" && (
                  <span
                    class="status"
                    style={{ backgroundColor: "rgb(113, 217, 22)" }}
                  >
                    Thành công
                  </span>
                )}
                {item?.isResolve === "REJECT" && (
                  <span
                    class="status"
                    style={{ backgroundColor: "rgb(230, 49, 9)" }}
                  >
                    Từ chối
                  </span>
                )}
                {!item?.isResolve && (
                  <span
                    class="status"
                    style={{ backgroundColor: "rgb(113, 217, 22)" }}
                  >
                    Thành công
                  </span>
                )}
              </div>
              <div class="card-body" style={{ gap: "5px" }}>
                <div class="col">
                  <p>Loại tài sản</p>
                  <h5>Ví người dùng chính</h5>
                </div>

                <div class="col">
                  <p>Số tiền biến động</p>
                  <h5>
                    {item?.isResolve
                      ? item?.moneyWithDraw?.toLocaleString()
                      : (+item?.balance)?.toLocaleString()}
                  </h5>
                </div>
                <div class="col">
                  <p>Số dư khi biến động</p>
                  <h5>
                    {item?.isResolve
                      ? item?.moneyAfter?.toLocaleString()
                      : (+item?.currentMoney)?.toLocaleString()}
                  </h5>
                </div>
              </div>
              <div class="card-footer">
                {!!item?.note && <p class="text-red">Lý do: {item?.note}</p>}

                <p>{new Date(item?.createdAt).toLocaleString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HistoryBalance;
