import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const Address = () => {
  const { user } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  return (
    <>
      <div className="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 className="bg_header_name ">Địa chỉ của tôi</h1>
        <div className="header_R"></div>
        <div>
          <span id="signout" className="btn_inforOut"></span>
        </div>
      </div>
      <div className="container_main">
        <div className="address_container">
          <p>Địa chỉ</p>
          <div className="card-address">
            <div className="card-body">
              {user &&
                user?.address &&
                user?.address?.map((item) => (
                  <div className="item">
                    <div>
                      <h5>
                        {item?.nameReceive} | <span> {user?.phone} </span>
                      </h5>
                      <p>{item?.address}</p>
                      {item.address ===
                        JSON.parse(localStorage.getItem("addressDefault"))
                          ?.address &&
                        item.phone ===
                          JSON.parse(localStorage.getItem("addressDefault"))
                            ?.phone && (
                          <div className="status">
                            <span className="text-orange">Mặc định</span>
                          </div>
                        )}
                    </div>
                    <Link to="/add-address" state={item} className="action">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="pen-to-square"
                        className="svg-inline--fa fa-pen-to-square "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                ))}

              <div className="card-footer">
                <button
                  type="button"
                  className="btn btn-add-address"
                  onClick={() => navigate("/add-address")}
                >
                  <svg
                    style={{
                      width: "12px",
                    }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-plus"
                    className="svg-inline--fa fa-circle-plus "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                    ></path>
                  </svg>
                  <span>Thêm địa chỉ mới</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
