import type { RouteObject } from "react-router";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import Login from "../pages/login";
import WrapperRouteComponent from "./WrapperRouteComponent";
import Register from "pages/register";
import Home from "pages/home";
import Product from "pages/home/Product";
import Me from "pages/me";
import Cart from "pages/cart";
import requestService from "api/request";
import { useEffect } from "react";
import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { setUser } from "store/app";
import Confirm from "pages/confirm";
import List from "pages/list";
import Address from "pages/address";
import CreateAddress from "pages/add-address";
import PaymentOrder from "pages/Payment";
import History from "pages/History";
import Deposit from "pages/Deposit";
import Withdraws from "pages/withdraws";
import AddBank from "pages/AddBank";
import Bank from "pages/bank";
import HistoryBalance from "pages/HistoryBalance";
import HistoryBalamce from "pages/HistoryBalamce";
import Setting from "pages/setting";
import UpdateUser from "pages/UpdateUser";
import ChangePassword from "pages/ChangePassword";
import Help from "pages/Help";
import About from "pages/About";
import Search from "pages/search";
import CSKH from "pages/CSKH";

const routeList: RouteObject[] = [
  {
    path: "/login",
    element: <WrapperRouteComponent element={<Login />} />,
  },
  {
    path: "/register",
    element: <WrapperRouteComponent element={<Register />} />,
  },
  {
    path: "/",
    element: <WrapperRouteComponent element={<Home />} />,
  },
  {
    path: "/product",
    element: <WrapperRouteComponent element={<Product />} />,
  },
  {
    path: "/me",
    element: <WrapperRouteComponent element={<Me />} />,
  },
  {
    path: "/cart",
    element: <WrapperRouteComponent element={<Cart />} />,
  },
  {
    path: "/confirm",
    element: <WrapperRouteComponent element={<Confirm />} />,
  },
  {
    path: "/list",
    element: <WrapperRouteComponent element={<List />} />,
  },
  {
    path: "/address",
    element: <WrapperRouteComponent element={<Address />} />,
  },
  {
    path: "/add-address",
    element: <WrapperRouteComponent element={<CreateAddress />} />,
  },
  {
    path: "/paymentOrder",
    element: <WrapperRouteComponent element={<PaymentOrder />} />,
  },
  {
    path: "/history",
    element: <WrapperRouteComponent element={<History />} />,
  },
  {
    path: "/deposit",
    element: <WrapperRouteComponent element={<Deposit />} />,
  },
  {
    path: "/withdraw",
    element: <WrapperRouteComponent element={<Withdraws />} />,
  },
  {
    path: "/add-bank",
    element: <WrapperRouteComponent element={<AddBank />} />,
  },
  {
    path: "/bank",
    element: <WrapperRouteComponent element={<Bank />} />,
  },
  {
    path: "/history-balance",
    element: <WrapperRouteComponent element={<HistoryBalance />} />,
  },
  {
    path: "/balance-history",
    element: <WrapperRouteComponent element={<HistoryBalamce />} />,
  },
  {
    path: "/setting",
    element: <WrapperRouteComponent element={<Setting />} />,
  },
  {
    path: "/update-user",
    element: <WrapperRouteComponent element={<UpdateUser />} />,
  },
  {
    path: "/change-password",
    element: <WrapperRouteComponent element={<ChangePassword />} />,
  },
  {
    path: "/help",
    element: <WrapperRouteComponent element={<Help />} />,
  },
  {
    path: "/about",
    element: <WrapperRouteComponent element={<About />} />,
  },
  {
    path: "/search",
    element: <WrapperRouteComponent element={<Search />} />,
  },
  {
    path: "/cskh",
    element: <WrapperRouteComponent element={<CSKH />} />,
  },
];
const RenderRouter = () => {
  const element = useRoutes(routeList);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { user } = useAppSelector((state) => state.app);
  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const res = await requestService.post("/profile/refresh", {
        data: {
          refreshToken,
        },
      });
      if (res && res.data) {
        dispatch(setUser(res.data.user));
        localStorage.setItem("accessToken", res.data?.accessToken);
        localStorage.setItem("refreshToken", res.data?.refreshToken);
      }
    } catch (error) {
      console.log(error);
      message.error("Đã có lỗi xảy ra vui lòng đăng nhập lại");
      window.location.reload();
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("refreshToken")
    ) {
      if (location.pathname.includes("/login")) navigate("/");

      refreshToken();
    } else {
      if (!location.pathname.includes("/cskh")) return navigate("/login");
    }
  }, []);
  return (
    <>
      {element}
      {user &&
        location.pathname !== "/cskh" &&
        location.pathname !== "/product" &&
        location.pathname !== "/confirm" &&
        location.pathname !== "/cart" && (
          <div className="footer">
            <Link
              className={
                "footer_item " + (location.pathname === "/" && "active")
              }
              to="/"
            >
              {location.pathname === "/" ? (
                <img src="/assets/images/index_black.png" alt="" />
              ) : (
                <img src="/assets/images/home.png" alt="" />
              )}

              <h5>Trang đầu</h5>
            </Link>
            <Link
              className={
                "footer_item " + (location.pathname === "/list" && "active")
              }
              to="/list"
            >
              {location.pathname === "/list" ? (
                <img src="/assets/images/classify_black.png" alt="" />
              ) : (
                <img src="/assets/images/classify.png" alt="" />
              )}

              <h5>Phân loại</h5>
            </Link>
            <Link className="footer_item" to="/cart">
              <img src="/assets/images/cart.png" alt="" />
              <h5>Giỏ hàng</h5>
            </Link>

            <Link
              className={
                "footer_item " + (location.pathname === "/me" && "active")
              }
              to="/me"
            >
              {location.pathname === "/me" ? (
                <img src="/assets/images/user_black.png" alt="" />
              ) : (
                <img src="/assets/images/user.png" alt="" />
              )}

              <h5>Của tôi</h5>
            </Link>
          </div>
        )}
    </>
  );
};

export default RenderRouter;
