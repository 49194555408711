import { Button, Spin, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store";

const AddBank = () => {
  const { user } = useAppSelector((state) => state.app);
  const { state } = useLocation();
  const [form, setForm] = useState(null);
  const loading = useLoading("LOGIN");
  const [addressDefault, setAddressDefault] = useState(false);
  const [edit, setEdit] = useState(false);
  const { onLoading } = useFnLoading();
  const submit = async () => {
    onLoading({
      type: "LOGIN",
      value: true,
    });
    try {
      if (state?.add) {
        const res = await requestService.post("/profile/update-bank", {
          data: {
            ...form,
          },
        });
        if (res.data) {
          message.success("Cập nhật thành công");
          setTimeout(() => {
            window.location.href = "/me";
          }, [1000]);
        }
      } else {
        const newBankList = user?.bankList?.map((item) => {
          if (
            item?.numberBank === state?.bank?.numberBank &&
            item?.nameBank === state?.bank?.nameBank
          ) {
            return {
              ...state?.bank,
              ...form,
            };
          }
          return item;
        });
        await requestService.post("/profile/edit-bank", {
          data: {
            bankInfo: addressDefault
              ? {
                  ...state?.bank,
                  ...form,
                }
              : user?.bankInfo,
            bankList: newBankList,
          },
        });
        message.success("Thành công");
        setTimeout(() => {
          window.location.href = "/bank";
        }, 1000);
      }
    } catch (error) {
      message.error(error.response.data.message || "Có lỗi xảy ra");
    }
    onLoading({
      type: "LOGIN",
      value: false,
    });
  };
  useEffect(() => {
    if (state?.bank && !state?.add) setForm({ ...state?.bank });
  }, [user, state]);
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <div class="bg_header ">
        <Link to="/bank" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Liên kết thẻ ngân hàng</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main bg-white">
        <div class="form-page  ">
          {state?.add ? (
            <form class="form_vertical form-payment mt-12 disabled">
              <div>
                <ul>
                  <li class="marginBottom">
                    <div for="bank_owner" class="form_T">
                      <span class="red">*</span>Tên chủ thẻ
                    </div>
                    <div class="form_In">
                      <input
                        class="ant-input"
                        id="bank_owner"
                        type="text"
                        value={form?.authorName}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            authorName: e.target.value,
                          })
                        }
                        placeholder="Tên chủ thẻ"
                        name="bank_owner"
                      />
                    </div>
                    <span class="error_t"></span>
                  </li>
                  <li class="marginBottom">
                    <div for="bank_number" class="form_T">
                      <span class="red">*</span>Số tài khoản
                    </div>
                    <div class="form_In">
                      <input
                        class="ant-input"
                        id="bank_number"
                        type="text"
                        placeholder="Số tài khoản"
                        name="bank_number"
                        value={form?.numberBank}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            numberBank: e.target.value,
                          })
                        }
                      />
                      <span class="error_t"></span>
                    </div>
                  </li>
                  <li class="marginBottom">
                    <div for="bank_code" class="form_T">
                      <span class="red">*</span>Tên ngân hàng
                    </div>
                    <div class="form_In">
                      <select
                        placeholder="Vui lòng chọn một ngân hàng"
                        disabled=""
                        name="bank_code"
                        className="bank-list"
                        value={form?.nameBank}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            nameBank: e.target.value,
                          })
                        }
                      >
                        <option value="ACB">Ngân hàng TMCP Á Châu (ACB)</option>
                        <option value="MBbank">
                          Ngân hàng quân đội (MBbank)
                        </option>
                        <option value="TPBank">Tiên Phong (TPBank)</option>
                        <option value="DAB">Ngân hàng TMCP Đông Á (DAB)</option>
                        <option value="SeABank">Đông Nam Á (SeABank)</option>
                        <option value="ABBANK">An Bình (ABBANK)</option>
                        <option value="BacABank">Bắc Á (BacABank)</option>
                        <option value="VietCapitalBank">
                          Bản Việt (VietCapitalBank)
                        </option>
                        <option value="MSB">Hàng hải Việt Nam (MSB)</option>
                        <option value="TCB">
                          Kỹ Thương Việt Nam (Techcombank)
                        </option>
                        <option value="KienLongBank">
                          Kiên Long (KienLongBank)
                        </option>
                        <option value="NamA">Nam Á (Nam A Bank)</option>
                        <option value="VPBank">
                          Việt Nam Thịnh Vượng (VPBank)
                        </option>
                        <option value="HDBank">
                          Phát triển Thành phố Hồ Chí Minh (HDBank)
                        </option>
                        <option value="OCB">Phương Đông (OCB)</option>
                        <option value="PVcombank">Đại chúng (PVcombank)</option>
                        <option value="VIB">Quốc tế Việt Nam (VIB)</option>
                        <option value="SCB">Sài Gòn (SCB)</option>
                        <option value="SGB">
                          Sài Gòn Công Thương (Saigonbank)
                        </option>
                        <option value="SHB">Sài Gòn – Hà Nội (SHBank)</option>
                        <option value="STB">
                          Sài Gòn Thương Tín (Sacombank)
                        </option>
                        <option value="VAB">Việt Á (VietABank)</option>
                        <option value="BVB">Bảo Việt (BaoVietBank)</option>
                        <option value="VietBank">
                          Việt Nam Thương Tín (VietBank)
                        </option>
                        <option value="Eximbank">
                          Xuất Nhập khẩu Việt Nam (Eximbank)
                        </option>
                        <option value="LienVietPostBank">
                          Bưu điện Liên Việt (LienVietPostBank)
                        </option>
                        <option value="Vietcombank">
                          Ngoại thương Việt Nam (Vietcombank)
                        </option>
                        <option value="VietinBank">
                          Công Thương Việt Nam (VietinBank)
                        </option>
                        <option value="BIDV">
                          Đầu tư và Phát triển Việt Nam (BIDV)
                        </option>
                        <option value="Oceanbank">Ngân hàng Oceanbank</option>
                        <option value="GPBank">Ngân hàng GPBank</option>
                        <option value="Agribank">Ngân hàng Agribank</option>
                        <option value="HSBC">Ngân hàng HSBC</option>
                        <option value="Citibank">Ngân hàng Citibank</option>
                        <option value="Shinhan">Ngân hàng SShinhan</option>
                      </select>
                      <span class="error_t"></span>
                    </div>
                  </li>
                </ul>
                <Button class="form_button" onClick={submit}>
                  Thêm
                </Button>
              </div>
            </form>
          ) : (
            <form class="form_vertical form-payment mt-12 disabled">
              <div>
                <ul>
                  <li class="marginBottom">
                    <div for="bank_owner" class="form_T">
                      <span class="red">*</span>Tên chủ thẻ
                    </div>
                    <div class="form_In">
                      <input
                        class="ant-input"
                        id="bank_owner"
                        type="text"
                        value={form?.authorName}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            authorName: e.target.value,
                          })
                        }
                        placeholder="Tên chủ thẻ"
                        name="bank_owner"
                      />
                    </div>
                    <span class="error_t"></span>
                  </li>
                  <li class="marginBottom">
                    <div for="bank_number" class="form_T">
                      <span class="red">*</span>Số tài khoản
                    </div>
                    <div class="form_In">
                      <input
                        class="ant-input"
                        id="bank_number"
                        type="text"
                        placeholder="Số tài khoản"
                        name="bank_number"
                        value={form?.numberBank}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            numberBank: e.target.value,
                          })
                        }
                      />
                      <span class="error_t"></span>
                    </div>
                  </li>
                  <li class="marginBottom">
                    <div for="bank_code" class="form_T">
                      <span class="red">*</span>Tên ngân hàng
                    </div>
                    <div class="form_In">
                      {!edit ? (
                        <div class="form_In">
                          <input
                            class="ant-input"
                            id="bank_number"
                            type="text"
                            placeholder="Số tài khoản"
                            name="bank_number"
                            value={form?.nameBank}
                            onClick={() => setEdit(true)}
                          />
                          <span class="error_t"></span>
                        </div>
                      ) : (
                        <select
                          placeholder="Vui lòng chọn một ngân hàng"
                          disabled=""
                          name="bank_code"
                          className="bank-list"
                          value={form?.nameBank}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              nameBank: e.target.value,
                            })
                          }
                        >
                          <option value="ACB">Á Châu (ACB)</option>
                          <option value="MBbank">
                            Ngân hàng quân đội (MBbank)
                          </option>
                          <option value="TPBank">Tiên Phong (TPBank)</option>
                          <option value="DAB">Đông Á (DAB)</option>
                          <option value="SeABank">Đông Nam Á (SeABank)</option>
                          <option value="ABBANK">An Bình (ABBANK)</option>
                          <option value="BacABank">Bắc Á (BacABank)</option>
                          <option value="VietCapitalBank">
                            Bản Việt (VietCapitalBank)
                          </option>
                          <option value="MSB">Hàng hải Việt Nam (MSB)</option>
                          <option value="TCB">
                            Kỹ Thương Việt Nam (Techcombank)
                          </option>
                          <option value="KienLongBank">
                            Kiên Long (KienLongBank)
                          </option>
                          <option value="NamA">Nam Á (Nam A Bank)</option>
                          <option value="VPBank">
                            Việt Nam Thịnh Vượng (VPBank)
                          </option>
                          <option value="HDBank">
                            Phát triển Thành phố Hồ Chí Minh (HDBank)
                          </option>
                          <option value="OCB">Phương Đông (OCB)</option>
                          <option value="PVcombank">
                            Đại chúng (PVcombank)
                          </option>
                          <option value="VIB">Quốc tế Việt Nam (VIB)</option>
                          <option value="SCB">Sài Gòn (SCB)</option>
                          <option value="SGB">
                            Sài Gòn Công Thương (Saigonbank)
                          </option>
                          <option value="SHB">Sài Gòn – Hà Nội (SHBank)</option>
                          <option value="STB">
                            Sài Gòn Thương Tín (Sacombank)
                          </option>
                          <option value="VAB">Việt Á (VietABank)</option>
                          <option value="BVB">Bảo Việt (BaoVietBank)</option>
                          <option value="VietBank">
                            Việt Nam Thương Tín (VietBank)
                          </option>
                          <option value="Eximbank">
                            Xuất Nhập khẩu Việt Nam (Eximbank)
                          </option>
                          <option value="LienVietPostBank">
                            Bưu điện Liên Việt (LienVietPostBank)
                          </option>
                          <option value="Vietcombank">
                            Ngoại thương Việt Nam (Vietcombank)
                          </option>
                          <option value="VietinBank">
                            Công Thương Việt Nam (VietinBank)
                          </option>
                          <option value="BIDV">
                            Đầu tư và Phát triển Việt Nam (BIDV)
                          </option>
                          <option value="Oceanbank">Ngân hàng Oceanbank</option>
                          <option value="GPBank">Ngân hàng GPBank</option>
                          <option value="Agribank">Ngân hàng Agribank</option>
                          <option value="HSBC">
                            Ngân hàng TNHH MTV HSBC (HSBC)
                          </option>
                          <option value="Citibank">
                            Ngân hàng Citibank Việt Nam (Citibank)
                          </option>
                          <option value="Shinhan">Ngân hàng Shinhan</option>
                        </select>
                      )}
                      <span class="error_t"></span>
                    </div>
                  </li>
                  <li class="marginBottom">
                    <div for="bank_phone" class="form_T">
                      Đặt làm tài khoản mặc định
                    </div>
                    <div class="form_In">
                      <label class="switch" for="is_default">
                        <input
                          type="checkbox"
                          id="is_default"
                          onChange={(e) => setAddressDefault(e.target.checked)}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </li>
                </ul>
                <Button class="form_button" onClick={submit}>
                  Xác nhận
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AddBank;
