import { message } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Deposit = () => {
  const [number, setNumber] = useState(0);
  return (
    <>
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name left">Nạp tiền</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main bodyForm deposit-page">
        <div class="balance-deposit">
          <h5>Số dư của tôi: 0 đ</h5>
        </div>
        <div class="suggest-number">
          <span
            class={"btn-num " + (number === 10000 && "active")}
            onClick={() => setNumber(10000)}
          >
            10000
          </span>
          <span
            class={"btn-num " + (number === 50000 && "active")}
            onClick={() => setNumber(50000)}
          >
            50000
          </span>
          <span
            class={"btn-num " + (number === 100000 && "active")}
            onClick={() => setNumber(100000)}
          >
            100000
          </span>
          <span
            class={"btn-num " + (number === 500000 && "active")}
            onClick={() => setNumber(500000)}
          >
            500000
          </span>
          <span
            class={"btn-num " + (number === 1000000 && "active")}
            onClick={() => setNumber(1000000)}
          >
            1000000
          </span>
          <span
            class={"btn-num " + (number === 2000000 && "active")}
            onClick={() => setNumber(2000000)}
          >
            2000000
          </span>
        </div>
        <form method="post" name="form" class="form-payment">
          <div class="form">
            <ul>
              <li>
                <div class="form_T">Số tiền nạp</div>
                <div class="form_In">
                  <div class="ant-input-number">
                    <div class="ant-input-number-handler-wrap">
                      <span
                        unselectable="on"
                        role="button"
                        aria-label="Increase Value"
                        aria-disabled="false"
                        class="ant-input-number-handler ant-input-number-handler-up"
                      >
                        <span
                          role="img"
                          aria-label="up"
                          class="anticon anticon-up ant-input-number-handler-up-inner"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="up"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"></path>
                          </svg>
                        </span>
                      </span>
                      <span
                        unselectable="on"
                        role="button"
                        aria-label="Decrease Value"
                        aria-disabled="false"
                        class="ant-input-number-handler ant-input-number-handler-down"
                      >
                        <span
                          role="img"
                          aria-label="down"
                          class="anticon anticon-down ant-input-number-handler-down-inner"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="down"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div class="ant-input-number-input-wrap">
                      <input
                        autocomplete="off"
                        role="spinbutton"
                        aria-valuemin="100000"
                        step="1"
                        placeholder="Nhập số tiền"
                        inputmode="decimal"
                        class="ant-input-number-input"
                        value={number}
                      />
                    </div>
                  </div>
                </div>
                <p class="help-text mt-6">
                  Vui lòng nhập số tiền từ 100,000 đ đến 1,000,000,000 đ
                </p>
              </li>
            </ul>
            <div class="form_button">
              <input
                type="button"
                class="confirmSent confirmSentM fancybox w100"
                disabled=""
                value="Xác nhận"
                onClick={() => {
                  message.warning("Liên hệ CSKH để nạp tiền");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Deposit;
