import React from "react";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <>
      <div class="bg_header ">
        <Link to="/setting" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Cập nhật thông tin</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main company-page">
        <div class="help-content">
          <p>
            - Xin chào cộng tác viên tham gia việc làm cùng hệ thống nền tảng
            bán hàng Shopee
          </p>
          <p>
            - Để ngăn chặn các hoạt động rửa tiền bất hợp pháp rửa tiền theo
            khoản 1, điều 3 nghị định số 74/2005/NĐ-CP ngày 07/06/2005 người
            dùng phải hoàn thành nhiệm vụ và rút tiền trong cùng một ngày. Sau
            khi xác nhận rút tiền thành công, thời gian từ 1-5 phút, khoảng thời
            gian cao điểm không quá 30 phút và thời gian nhận do bên các ngân
            hàng.
          </p>
          <p>
            - Tham gia công việc cộng tác viên cùng hệ thống nền tảng bán hàng
            Shopee bằng hình thức nhận đơn hàng và hoàn thành nhiệm vụ :
          </p>
          <div>
            <p>1. Đăng ký tài khoản.</p>
            <p>2. Nhận thưởng từ hệ thống và nhận đơn hàng.</p>
            <p>3. Nạp tiền khi số dư tài khoản không đủ hoàn thiện đơn hàng.</p>
            <p>4. Hoàn thành nhiệm vụ của đơn hàng.</p>
            <p>
              5. Rút tiền gốc và lợi nhuận hoa hồng về tài khoản ngân hàng của
              bạn.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
