import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { message } from "antd";
const Setting = () => {
  const navigate = useNavigate();
  return (
    <>
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Cài đặt</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main">
        <ul class="memberForm">
          <li
            role="presentation"
            class=""
            onClick={() => navigate("/update-user")}
          >
            <div>
              <div class="title">Thông tin cá nhân</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </li>
          <li
            role="presentation"
            class=""
            onClick={() => navigate("/change-password")}
          >
            <div>
              <div class="title">Mật khẩu</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </li>

          <li
            role="presentation"
            class=""
            onClick={() => message.success("Đã xóa bộ nhớ")}
          >
            <div>
              <div class="title">Xoá bộ nhớ</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </li>
          <li role="presentation" class="" onClick={() => navigate("/help")}>
            <div>
              <div class="title">Trợ giúp</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </li>
          <li role="presentation" class="" onClick={() => navigate("/about")}>
            <div>
              <div class="title">Về chúng tôi</div>
            </div>
            <div>
              <RightOutlined />
            </div>
          </li>
          <button
            type="button"
            class="ant-btn ant-btn-default btn-logout"
            onClick={() => {
              localStorage.clear();
              window.location.href = "/login";
            }}
          >
            <span>Đăng xuất</span>
          </button>
        </ul>
      </div>
    </>
  );
};

export default Setting;
