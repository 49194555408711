import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {},
  user: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = {
        ...state.loading,
        [action.payload.type]: action.payload.value,
      };
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setLoading, setUser } = appSlice.actions;

export default appSlice.reducer;
