import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HistoryBalamce = () => {
  const [history, setHistory] = useState([]);
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  useEffect(() => {
    const fetch = async () => {
      onLoading({
        type: "LOGIN",
        value: true,
      });
      try {
        const res = await requestService.get("/profile/history-balance-action");
        if (res && res.data) {
          setHistory(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
      onLoading({
        type: "LOGIN",
        value: false,
      });
    };
    fetch();
  }, []);
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Biến động số dư</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main">
        <div class="recharge ">
          <div class="ant-collapse ant-collapse-icon-position-start">
            {history.map((item) => (
              <div class="ant-collapse-item">
                <div
                  class="ant-collapse-header"
                  aria-expanded="false"
                  aria-disabled="false"
                  role="button"
                  tabindex="0"
                >
                  <span class="ant-collapse-header-text">
                    <span class="ant-collapse-header-text">
                      <div class="tableTitle">
                        <div class="left">
                          <p>{new Date(item?.createdAt)?.toLocaleString()}</p>
                        </div>
                        <div class="right">
                          <div class="flex">
                            <p class="flex-col-2 text-left">
                              {item?.type}
                              <br /> {(+item?.balance)?.toLocaleString()}
                            </p>
                            <p class="flex-col-3 text-right">
                              Số dư: {(+item?.currentMoney)?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default HistoryBalamce;
