import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const History = () => {
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  const [status, setStatus] = useState("ALL");
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetch = async () => {
      try {
        onLoading({
          type: "LOGIN",
          value: true,
        });
        const res = await requestService.get(
          "/profile/order-history?status=" + status
        );
        if (res && res.data) {
          console.log(res.data.data);
          setOrders(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
      onLoading({
        type: "LOGIN",
        value: false,
      });
    };
    fetch();
  }, [status]);
  return (
    <>
      <div class="bg_header ">
        <span class="btn_back">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            class="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </span>
        <h1 class="bg_header_name ">Lịch sử đặt hàng</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main">
        <div class="page-tab">
          <div
            class={"item " + (status === "ALL" && "active")}
            onClick={() => setStatus("ALL")}
          >
            Tất cả
          </div>
          <div
            class={"item " + (status === "PAYMENT" && "active")}
            onClick={() => setStatus("PAYMENT")}
          >
            Kiểm duyệt
          </div>
          <div
            class={"item " + (status === "RESOLVE" && "active")}
            onClick={() => setStatus("RESOLVE")}
          >
            Thành công
          </div>
          <div
            class={"item " + (status === "REJECT" && "active")}
            onClick={() => setStatus("REJECT")}
          >
            Đã huỷ
          </div>
        </div>
        <div class="product-container">
          <div class="list-product order-history">
            {orders?.map((o) => (
              <div class="order-item">
                <div class="cell-group">
                  <div class="cell-item">
                    <div class="cell-item-hd">
                      <div class="cell-hd-title">Mã đơn hàng: {o?._id}</div>
                    </div>
                    <div class="cell-item-ft">
                      <div class="cell-ft-text">
                        <span>
                          {o?.progress === "PENDING" && "Chưa thanh toán"}
                          {o?.progress === "PAYMENT" && "Chờ xét duyệt"}
                          {o?.progress === "RESOLVE" && "Thành công"}
                          {o?.progress === "REJECT" && "Hủy"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  {o?.cart?.map((c) => (
                    <div class="item-body">
                      <div class="img-prod">
                        <img src={c?.product?.img_product} alt="" />
                      </div>
                      <div class="info">
                        <p class="name">{c?.product?.name_product}</p>
                        <p class="price">
                          <b class="green">
                            {c?.product?.price_product?.toLocaleString()} đ X{" "}
                            {c?.quantity}
                          </b>
                        </p>
                        <p class="price">
                          <b class="text-orange">
                            {(
                              c?.product?.price_product * c?.quantity
                            ).toLocaleString()}{" "}
                            đ
                          </b>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div class="cell-group">
                  <div class="cell-item">
                    <div class="cell-item-ft goods-num">
                      <div class="cell-ft-text">
                        <span>Tổng cộng:</span>
                        <span class="text-orange">
                          {o?.totalMoney?.toLocaleString()} đ
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-list-button">
                  <button type="button" class="btn btn-circle btn-g">
                    Xem chi tiết
                  </button>
                  {o?.progress === "PENDING" && (
                    <button
                      type="button"
                      class="btn btn-circle btn-w"
                      onClick={() => {
                        navigate("/paymentOrder", {
                          state: o,
                        });
                      }}
                    >
                      Thanh toán ngay
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
    </>
  );
};

export default History;
