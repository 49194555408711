import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const CSKH = () => {
  const n = useNavigate();
  const { state } = useLocation();
  const { user } = useAppSelector((state) => state.app);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 5000);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {load && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={load} />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "50px",
          height: "50px",
          background: "#fff",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          if (user) n("/me");
          else n("/login");
        }}
      >
        <i className="fas fa-home"></i>
      </div>
      <iframe
        src="https://tawk.to/chat/6475831fad80445890efd313/1h1lggd45"
        style={{
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};

export default CSKH;
