import { InputNumber, Spin, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";

const Withdraws = () => {
  const { user } = useAppSelector((state) => state.app);
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  const [moneyWithDraw, setmoneyWithDraw] = useState(0);
  const formatDisplayValue = (value) => {
    return value.toLocaleString("vi-VN"); // Định dạng hiển thị giá trị
  };
  const handleInputChange = (e) => {
    // Loại bỏ dấu phẩy để lấy giá trị thực sự
    const rawValue = e.target.value.replace(/,/g, "");
    setmoneyWithDraw(e.target.value);
  };
  const submit = async () => {
    onLoading({
      type: "LOGIN",
      value: true,
    });
    try {
      const res = await requestService.post("/profile/withdraw", {
        data: {
          moneyWithDraw,
        },
      });
      if (res && res.data) {
        message.success("Đã rút tiền thành công");
        setTimeout(() => {
          window.location.reload();
        }, [1000]);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Có lỗi xảy ra");
    }
    onLoading({
      type: "LOGIN",
      value: false,
    });
  };
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <div class="bg_header ">
        <Link to="/me" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name left">Rút tiền</h1>
        <div class="header_R">
          <div class="icon_inforID" id="GameMenu">
            <div class="icon_inforMoney">
              <span>{user?.money?.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main bodyForm">
        <div class="">
          <div class="">
            <form method="post" name="form" class="form-payment">
              <div class="form">
                <ul>
                  <li>
                    <div class="balance-card">
                      <h4>
                        Số dư tài khoản:{" "}
                        <span>{user?.money?.toLocaleString()} đ</span>
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div class="card-bank">
                      {user?.bankInfo ? (
                        <div class="card-body">
                          <div class="item">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="building-columns"
                              class="svg-inline--fa fa-building-columns bank-icon"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8.1-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zm128-96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
                              ></path>
                            </svg>
                            <div class="info">
                              <h5>
                                <b>Tên chủ thẻ</b>: {user?.bankInfo?.authorName}
                              </h5>
                            </div>
                          </div>
                          <div class="item">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="building-columns"
                              class="svg-inline--fa fa-building-columns bank-icon"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8.1-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zm128-96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
                              ></path>
                            </svg>
                            <div class="info">
                              <h5>
                                <b>Ngân hàng</b>: {user?.bankInfo?.nameBank}
                              </h5>
                            </div>
                          </div>
                          <div class="item">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="building-columns"
                              class="svg-inline--fa fa-building-columns bank-icon"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8.1-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zm128-96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
                              ></path>
                            </svg>
                            <div class="info">
                              <h5>
                                <b>Số tài khoản</b>:{" "}
                                {user?.bankInfo?.numberBank}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="card-body"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: " 20px 10px",
                          }}
                        >
                          <div>Chưa liên kết ngân hàng</div>
                          <Link to="/add-bank">Liên kết ngay</Link>
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div class="form_T">Số tiền rút</div>
                    <div class="form_In">
                      <div class="ant-input-number">
                        <div class="ant-input-number-handler-wrap">
                          <span
                            unselectable="on"
                            role="button"
                            aria-label="Increase Value"
                            aria-disabled="false"
                            class="ant-input-number-handler ant-input-number-handler-up"
                          >
                            <span
                              role="img"
                              aria-label="up"
                              class="anticon anticon-up ant-input-number-handler-up-inner"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="up"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"></path>
                              </svg>
                            </span>
                          </span>
                          <span
                            unselectable="on"
                            role="button"
                            aria-label="Decrease Value"
                            aria-disabled="true"
                            class="ant-input-number-handler ant-input-number-handler-down ant-input-number-handler-down-disabled"
                          >
                            <span
                              role="img"
                              aria-label="down"
                              class="anticon anticon-down ant-input-number-handler-down-inner"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="down"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                              </svg>
                            </span>
                          </span>
                        </div>
                        <div class="ant-input-number-input-wrap">
                          <InputNumber
                            style={{ width: "100%", border: "none" }}
                            defaultValue={0}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            onChange={(key) => {
                              setmoneyWithDraw(key);
                            }}
                            // class="ant-input-number-input"
                          />
                        </div>
                      </div>
                    </div>
                    <p class="help-text mt-6">
                      Vui lòng nhập số tiền từ 100,000 đ đến 1,000,000,000 đ
                    </p>
                  </li>
                </ul>
                <div class="form_button">
                  <input
                    type="button"
                    class="confirmSent confirmSentM fancybox w100"
                    value="Xác nhận"
                    onClick={submit}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraws;
