import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
const Home = () => {
  const [init, setinit] = useState(null);
  const loading = useLoading("INIT");
  const { onLoading } = useFnLoading();
  const getInits = async () => {
    onLoading({
      type: "INIT",
      value: true,
    });
    try {
      const res = await requestService.get("/profile/init");
      if (res && res.data) {
        setinit(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    onLoading({
      type: "INIT",
      value: false,
    });
  };
  useEffect(() => {
    getInits();
  }, []);
  const n = useNavigate();
  return (
    <div className="container_main home-page">
      <div className="search-container">
        <div className="input-wrapper" onClick={() => n("/search")}>
          <input
            className="ant-input ant-input-disabled input-search-home"
            placeholder="Tìm kiếm trong Shopee Mail"
            disabled
            type="text"
            value=""
          />
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="magnifying-glass"
            className="svg-inline--fa fa-magnifying-glass"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
            ></path>
          </svg>
        </div>
      </div>
      {init &&
        init.map((item, index) => (
          <div className="mt-12" key={index}>
            <div className="slide-home">
              <Swiper
                className="mySwiper"
                loop
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                {index === 0 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://magiamgia.com/wp-content/uploads/2021/02/Shopee-3.3-Sieu-sale-hoan-xu-50.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 0 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://s3-ap-southeast-1.amazonaws.com/storage.adpia.vn/affiliate_document/multi/shopee-hen-gio-san-sale-d-day-0303.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 1 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="	https://s3-ap-southeast-1.amazonaws.com/storage.adpia.vn/affiliate_document/multi/shopee-chao-thang-3-700X400.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 1 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://treobangron.com.vn/wp-content/uploads/2023/01/banner-shopee-12.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 2 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://magiamgiashopee.vn/wp-content/uploads/2023/02/shopee-3.3.webp"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 2 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://treobangron.com.vn/wp-content/uploads/2023/01/banner-shopee-12.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 3 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://down-vn.img.susercontent.com/file/vn-50009109-aecb375cf8e0d6cc832b7adcbb431003"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
                {index === 3 && (
                  <SwiperSlide>
                    <img
                      alt=""
                      src="https://icdn.24h.com.vn/upload/1-2021/images/2021-03-03/11-1614736504-710-width660height385.jpg"
                      style={{ height: "175px" }}
                    />
                  </SwiperSlide>
                )}
              </Swiper>
              {/* <div className="swiper swiper2 swiper-initialized swiper-horizontal swiper-ios swiper-backface-hidden">
                <div className="swiper-wrapper">
                  <div className="swiper-slide swiper-slide-active"></div>
                </div>
                <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock">
                  <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                </div>
              </div> */}
            </div>
            <div className="product-container">
              <div className="title">
                <h5>{item?.categoryId?.name}</h5>
                <div className="right">
                  <Link to="/search">Xem thêm</Link>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-right"
                    className="svg-inline--fa fa-angle-right"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="list-product">
                <div className="scroll-view-content">
                  {item.products.map((p) => (
                    <Link
                      className="item"
                      to={`/product`}
                      state={p}
                      key={p?._id}
                    >
                      <img src={p?.img_product} alt="" className="avatar" />
                      <div className="name">{p?.name_product}</div>
                      <div className="price">
                        {(
                          p?.price_product?.toLocaleString() || 0
                        ).toLocaleString()}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
    </div>
  );
};

export default Home;
