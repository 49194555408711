import { Spin } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Search = () => {
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState(null);
  const loading = useLoading("INIT");
  const { onLoading } = useFnLoading();
  useEffect(() => {
    const fetch = async () => {
      onLoading({
        type: "INIT",
        value: true,
      });
      try {
        const res = await requestService.get(
          "/profile/products" +
            `?name_product=${query?.name_product}&category=${query?.category}`
        );

        if (res && res.data) {
          setProducts(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
      onLoading({
        type: "INIT",
        value: false,
      });
    };
    fetch();
  }, [query]);
  return (
    <div class="product-search-page">
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,.4)",
            height: "100%",
          }}
        >
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
          fontWeight: 700,
        }}
      >
        Tìm kiếm
      </div>
      <div class="search-container">
        <div class="input-wrapper">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="magnifying-glass"
            class="svg-inline--fa fa-magnifying-glass "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
            ></path>
          </svg>
          <input
            class="ant-input input-search-home"
            placeholder="Tìm kiếm trong Shopee Mail"
            type="text"
            value={query?.name_product}
            onChange={(e) =>
              setQuery({ ...query, name_product: e.target.value })
            }
          />
        </div>
        <button type="button" class="btn btn-search">
          Tìm
        </button>
      </div>
      <div class="search-body">
        <h5>Tìm kiếm thấy được</h5>
        <div class="list-cat">
          <span onClick={() => setQuery({ ...query, category: "" })}>
            Tất cả
          </span>
          <span
            onClick={() =>
              setQuery({ ...query, category: "650e4cfd9ff0dec63e8684a9" })
            }
          >
            Khu mỹ phẩm <br /> hoàn trả 10%
          </span>
          <span
            onClick={() =>
              setQuery({ ...query, category: "650e4d894b97023ed7c0a9b9" })
            }
          >
            Khu giày dép <br /> hoàn trả 20%
          </span>
          <span
            onClick={() =>
              setQuery({ ...query, category: "650e4da24b97023ed7c0a9bb" })
            }
          >
            Khu thiết bị điện tử <br />
            hoàn trả 30%
          </span>
          <span
            onClick={() =>
              setQuery({ ...query, category: "650e4dc04b97023ed7c0a9bd" })
            }
          >
            Khu trang sức <br />
            hoàn trả 40%
          </span>
        </div>
      </div>
      <div class="search-body">
        <div class="product-container">
          <div class="list-product">
            {products.map((item) => (
              <Link class="item" to="/product" state={item}>
                <div class="img-prod">
                  <img src={item?.img_product} alt="" />
                </div>
                <div class="info">
                  <h2>{item?.name_product}</h2>
                  <p class="text-orange">
                    {item?.price_product?.toLocaleString()} đ
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
