import { Button, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store";

const CreateAddress = () => {
  const { user } = useAppSelector((state) => state.app);
  const { state } = useLocation();

  const [form, setform] = useState(null);
  const loading = useLoading("LOGIN");
  const { onLoading } = useFnLoading();
  const [addressDefault, setAddressDefault] = useState(false);
  const addAddress = async () => {
    onLoading({
      type: "LOGIN",
      value: true,
    });
    try {
      const res = await requestService.post("/profile/update-address", {
        data: { ...form, isEdit: !!state },
      });
      if (res && res.data) {
        if (addressDefault) {
          localStorage.setItem("addressDefault", JSON.stringify(form));
        }
        message.success("Đã thêm địa chỉ mới");
        setTimeout(() => {
          window.location.href = "/address";
        }, 1500);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Có lỗi xảy ra");
    }
    onLoading({
      type: "LOGIN",
      value: false,
    });
  };
  useEffect(() => {
    if (state) setform({ ...state });
  }, [state]);
  return (
    <>
      <div className="bg_header ">
        <Link to="/address" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 className="bg_header_name ">Địa chỉ mới</h1>
        <div className="header_R"></div>
        <div>
          <span id="signout" className="btn_inforOut"></span>
        </div>
      </div>
      <div className="container_main bg-white">
        <div className="form-page  ">
          <div className="form_vertical form-payment mt-12">
            <div>
              <ul>
                <li className="marginBottom">
                  <div for="name" className="form_T">
                    <span className="red">*</span>Tên người nhận hàng
                  </div>
                  <div className="form_In">
                    <input
                      className="ant-input"
                      id="name"
                      value={form?.nameReceive}
                      onChange={(e) =>
                        setform({ ...form, nameReceive: e.target.value })
                      }
                      type="text"
                      placeholder="Tên người nhận hàng"
                      name="name"
                    />
                  </div>
                  <span className="error_t"></span>
                </li>
                <li className="marginBottom">
                  <div for="phone" className="form_T">
                    <span className="red">*</span>Số điện thoại
                  </div>
                  <div className="form_In">
                    <input
                      className="ant-input"
                      id="phone"
                      type="text"
                      placeholder="Số điện thoại"
                      value={form?.phone}
                      onChange={(e) =>
                        setform({
                          ...form,
                          phone: e.target.value,
                        })
                      }
                      name="phone"
                    />
                    <span className="error_t"></span>
                  </div>
                </li>
                <li className="marginBottom">
                  <div for="address" className="form_T">
                    <span className="red">*</span>Địa chỉ
                  </div>
                  <div className="form_In">
                    <input
                      className="ant-input"
                      id="address"
                      type="text"
                      placeholder="Địa chỉ"
                      name="address"
                      value={form?.address}
                      onChange={(e) =>
                        setform({
                          ...form,
                          address: e.target.value,
                        })
                      }
                    />
                    <span className="error_t"></span>
                  </div>
                </li>
                <li className="marginBottom">
                  <div for="bank_phone" className="form_T">
                    Đặt làm địa chỉ mặc định
                  </div>
                  <div className="form_In">
                    <label className="switch" for="is_default">
                      <input
                        type="checkbox"
                        id="is_default"
                        onChange={(e) => setAddressDefault(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
              <Button onClick={addAddress} loading={loading} disabled={loading}>
                <div className="form_button">Lưu thay đổi</div>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="notification-container notification-container-empty">
        <div></div>
      </div>
    </>
  );
};

export default CreateAddress;
