import { message } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div class="bg_header ">
        <Link to="/setting" className="btn-back">
          <svg
            style={{ width: "12px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </Link>
        <h1 class="bg_header_name ">Cập nhật thông tin</h1>
        <div class="header_R"></div>
        <div>
          <span id="signout" class="btn_inforOut"></span>
        </div>
      </div>
      <div class="container_main company-page">
        <div class="help-content">
          <img
            src="https://core-api.shhope247.click/assets/upload/setting/1690110208_1689641502-logo.png"
            alt=""
          />
          <p class="text-center">V3.01.13.5.74.2</p>
          <button
            type="button"
            class="btn btn-remove"
            onClick={() => message.success("Đã xóa bộ nhớ đệm")}
          >
            Xoá bộ nhớ đệm
          </button>
        </div>
      </div>
    </>
  );
};

export default About;
